










































import Vue from 'vue';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import Organization from '@improve/common-utils/src/model/Organization';
import User from '@improve/common-utils/src/model/User';
import BaseButton from '../widgets/BaseButton.vue';
import BaseCheckbox from '../core/BaseCheckbox.vue';

@Component({
  name: 'TermsAndConditionsForm',
  components: {
    BaseButton,
    BaseCheckbox
  }
})
export default class TermsAndConditionsForm extends Vue {
  @Prop({ default: '' }) readonly email!: string;

  @Getter currentUser!: User;

  @Getter currentOrganization!: Organization;

  @Action fetchOrgAnonymously!: ActionMethod;

  tcLink = '';

  ppLink = '';

  agreedToTC = false;

  invalidForm = false;

  onCheckOrUncheck(value: boolean): void {
    this.agreedToTC = value;
    this.invalidForm = !this.agreedToTC;
  }

  created(): void {
    this.getOrgTC();
  }

  async getOrgTC(): Promise<void> {
    let lang = 'EN';
    let org: Organization = new Organization();

    if (this.currentUser) {
      lang = this.currentUser.language;
      org = this.currentOrganization;
    } else if (this.email) {
      org = await this.fetchOrgAnonymously(this.email);
    }

    this.tcLink = org.getTermsAndConditionsLink(lang) || '';
    this.ppLink = org.getPrivacyPolicyLink(lang) || '';
  }

  async submitAgreement(): Promise<void> {
    // Not validating through BaseFormControl because custom msg needed
    this.invalidForm = !this.agreedToTC;
    if (this.invalidForm) {
      return;
    }
    this.$emit('onSubmit');
  }
}
