














































import { Component, Prop } from 'vue-property-decorator';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  name: 'BaseCheckbox',
  components: {
    BaseControlWrapper
  }
})
export default class BaseCheckbox extends BaseFormControl {
  @Prop({ default: null }) readonly checkboxText!: string;

  @Prop({ default: null }) readonly height!: number;

  @Prop({ default: null }) readonly width!: number;

  created(): void {
    super.created();
  }
}
