enum UIStates {
  ACCEPT_TC = 'ACCEPT_TC',
  CONFIRMATION = 'CONFIRMATION',
  ERROR_OCCURRED = 'ERROR_OCCURRED',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  SUBMIT_CREDENTIALS = 'SUBMIT_CREDENTIALS',
  SUBMIT_EMAIL = 'SUBMIT_EMAIL',
}

export default UIStates;
